import tinycolor from 'tinycolor2';

const ready = () => {
  const colours = $('body').data('colours');

  if (colours.background && colours.background !== '') {
    const basics = tinycolor(colours.background);
    const banner = tinycolor(colours.background).darken();
    const content = tinycolor(colours.background).setAlpha(0.8);

    $('body').css({
      backgroundColor: banner.darken(10),
    });

    $('#banner').css({ 'background-color': banner });

    $('#basics').css({
      'background-color': basics,
      color: colours.text,
    });

    $('#content .content').css({
      'background-color': content,
      color: colours.text,
    });

    $('#faqs').css({
      backgroundColor: banner,
      color: colours.text,
    });

    $('#faqs a').css({
      color: colours.text,
    });

    $('#loading h1 span').css({
      color: colours.button_background,
    });
  }

  if (colours.button_background && colours.button_background !== '') {
    $('.btn-primary').css({
      'background-color': tinycolor(colours.button_background),
      'border-color': tinycolor(colours.button_background).darken(),
    });

    $('#loading h1 span').css({
      color: colours.button_background,
    });

    $('#loading .loader, #loading .processing').css({
      borderBottomColor: colours.button_background,
      borderTopColor: colours.button_background,
      borderRightColor: colours.button_background,
    });
  }

  if (colours.button_colour && colours.button_colour !== '') {
    $('.btn-primary').css({ color: tinycolor(colours.button_colour) });
  }

  if ($('#new-device-connection').length > 0) {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('c');
    const token = params.get('token');

    $('#new-device-connection').on('submit', () => {
      $('#loading').removeClass('d-none');
    });

    if (token) {
      $('#guest_device_connection_token').val(token);
      $('#new-device-connection').submit();
    }

    if (code) {
      $('#guest_device_connection_code').val(code);
      $('#new-device-connection').submit();
    }
  }

  $('#localiser-select').on('change', () => $('#localiser-form').submit());
};

$(ready);
// $(document).on('turbolinks:load', ready);
